import { fireEntryDispatches } from '../kickstart';
import buildSignUp from '../sign-up';
import buildTrending from '../trending/on-scroll';
import buildNewOnTrib from '../new-on-trib/on-scroll';

fireEntryDispatches();

async function buildRecentEvents() {
  const module = await import(/* webpackChunkName: "build-recent-events" */ '../recent-events');
  module.default();
}

export * from '../kickstart';
export { buildTrending, buildNewOnTrib, buildRecentEvents, buildSignUp };
