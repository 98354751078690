import buildApiUrl from '../build-api-url';
import { ARTICLE_BASE, ARTICLE_FIELDS } from '../constants';

import { EApiFilter } from '../types';

const NEW_ON_TRIB_API_META = {
  url: ARTICLE_BASE,
  fields: ARTICLE_FIELDS,
  filters: [
    {
      name: EApiFilter.Limit,
      items: [{ value: 5, include: true }],
    },
    {
      name: EApiFilter.FrontPage,
      items: [{ value: 'true', include: true }],
    },
  ],
};

export const NEW_ON_TRIB_API_URL = buildApiUrl(NEW_ON_TRIB_API_META);

export const SELECTOR = 'new-on-trib';
