import buildApiUrl from '../build-api-url';
import { TRENDING_BASE, ARTICLE_FIELDS } from '../constants';

import { EApiFilter } from '../types';

const TRENDING_API_META = {
  url: TRENDING_BASE,
  fields: ARTICLE_FIELDS,
  filters: [
    {
      name: EApiFilter.Limit,
      items: [{ value: 5, include: true }],
    },
  ],
};

export const TRENDING_API_URL = buildApiUrl(TRENDING_API_META);

export const SELECTOR = 'trending';
