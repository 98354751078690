import bindScrollActions from '../utils/scroll-actions';
import select from '../utils/select';
import { SELECTOR } from './constants';

import { IBuildTrendingParams } from './types';

export default function buildTrendingOnScroll(
  config: IBuildTrendingParams
): void {
  const targetElement = select(`#${SELECTOR}`);
  const callback = async (): Promise<void> => {
    const mod = await import(/* webpackChunkName: "build-trending", webpackPrefetch: true */ './index');
    mod.default(config);
  };

  bindScrollActions({
    callback,
    elements: [targetElement],
    doOnce: true,
    observerOptions: { rootMargin: `${window.innerHeight}px` },
  });
}
