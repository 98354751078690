import bindScrollActions from '../utils/scroll-actions';
import select from '../utils/select';
import { SELECTOR } from './constants';

import { IBuildNewOnTribParams } from './types';

export default function buildNewOnTribOnScroll(
  config: IBuildNewOnTribParams
): void {
  const targetElement = select(`#${SELECTOR}`);
  const callback = async (): Promise<void> => {
    const mod = await import(/* webpackChunkName: "build-new-on-trib", webpackPrefetch: true */ './index');
    mod.default(config);
  };

  bindScrollActions({
    callback,
    elements: [targetElement],
    doOnce: true,
    observerOptions: { rootMargin: `${window.innerHeight}px` },
  });
}
